import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import PostLink from "../components/post-link"

const IndexPage = ({
  data,
  pageContext,
  location,
}) => {
  
  const Posts = data.allSitePage.edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  const Builds = data.buildPages.edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  const Blogs = data.blogPages.edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  return (<Layout >
    <SEO title={pageContext.title}  pathname={location.pathname} />
    <h1 className="title is-2">Technological Crafting:</h1>
    <section className="section">
    <div className="content">
    <p>I intentionally refer to this as "Technological crafting" not being a "Maker" because all which I am doing descends from the emotional state of crafting.</p>

    <p>Soldering your own recreational electronics has more in common with sewing your own clothes or other such crafts than it does being a professional electronics engineer.</p>

    <p>Thus, this is my rambly set of screwing around, because I like woodworking and plastic fabrication and welding and electronics and sculpture.  And the times I've sewn things, it's been pretty damn fun.  So I guess technological crafting is all about that eccentric mis-mash of bizarre interests.</p>
    <div className="columns">
    <div className="column">
    <h2 className="title is-3">Project Builds</h2>
    {Builds}

    <h2 className="title is-3">Notes</h2>
    {Posts}
    </div>
    <div className="column">
    <h2 className="title is-3"><Link to="/make/blog">Blog</Link></h2>
    {Blogs}
    </div>
    </div>
    </div>
    </section>
  </Layout>);
}

export default IndexPage

export const pageQuery = graphql`
  {
    blogPages: allSitePage(limit: 5, sort: {fields: context___date, order: DESC}, filter: {context: {parent: {eq: "/make/blog"}, type: {ne: "Blog"}}}) {
      edges {
        node {
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
            type
          }
          id
          path
        }
      }
    }
    buildPages: allSitePage(sort: {fields: context___date, order: DESC}, filter: {context: {parent: {eq: "/make"}, type: {eq: "Build"}}}) {
      edges {
        node {
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
            type
          }
          id
          path
        }
      }
    }
    allSitePage(sort: {fields: context___date, order: DESC}, filter: {context: {parent: {eq: "/make"}, type: {nin: ["Blog","Build"]}}}) {
      edges {
        node {
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
            type
          }
          id
          path
        }
      }
    }
  }

`


export const frontmatter = {
  title: "Technological Crafting",
}