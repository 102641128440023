import React from "react"
import { Link } from "gatsby"

import audioIcon from "../../src/images/audio-1.svg"
import docIcon from "../../src/images/document-1.svg"
import folderIcon from "../../src/images/folder-1.svg"
import TimeAgo from "timeago-react"

const PostLink = ({ post }) => {
  let icon = docIcon
  if (post.node.context.type === "MusicRecording") {
    icon = audioIcon
  }
  if (post.node.context.type === "Collection") {
    icon = folderIcon
  }
  if (post.node.context.type === "Blog") {
    icon = folderIcon
  }
  return (
    <article className="media" key={post.node.path}>
      <figure className="media-left">
        <p className="icon is-large">
          <img src={icon} alt="icon" className="is-text-centered" />
        </p>
      </figure>
      <div className="media-content">
        <Link to={post.node.path} key={post.node.path}>
          {post.node.context.title}
        </Link>
        &ensp;
        {post.node.context.date && <small><TimeAgo datetime={post.node.context.date} locale="en" /></small>}
        <br />
        {post.node.context.excerpt}
      </div>
    </article>
  )
}
export default PostLink
